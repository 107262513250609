import * as React from "react";
import { IconButton, Tooltip, TextField, Grid, Typography, Menu, MenuItem, ListItemIcon } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";

import { ConfirmableAction } from "../../misc";
import { formatter } from "../../utils/formatter";
import { EstimateArea, EstimatePlanAreaTotal } from "../../api/models";

export interface AreaEditorSummaryProps {
  area: EstimateArea;
  areaTotal: EstimatePlanAreaTotal;
  moveUp: () => any;
  deleteArea: () => any;
  toggleExclude: () => any;
  onNameChange: (value: string) => any;
  onDescriptionChange: (value: string) => any;
}

export interface AreaEditorSummaryState {
  anchorEl: HTMLElement | null;
}

export class AreaEditorSummary extends React.Component<AreaEditorSummaryProps, AreaEditorSummaryState> {
  public state: AreaEditorSummaryState = { anchorEl: null };

  private menuButtonRef = React.createRef<HTMLElement>();

  public shouldComponentUpdate(nextProps: AreaEditorSummaryProps, nextState: AreaEditorSummaryState) {
    return nextProps.area !== this.props.area || nextProps.areaTotal !== this.props.areaTotal || nextState.anchorEl !== this.state.anchorEl;
  }

  public render() {
    const { area, areaTotal } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const descriptionTooltip = <pre>{area.description}</pre>;

    const minHours = !!areaTotal
      ? Math.round(areaTotal.itemTotals.map((t) => t.adjustedRangeLow).reduce((a, b) => a + b, 0) * 100) / 100
      : 0;
    const buffer = !!areaTotal ? Math.round(areaTotal.itemTotals.map((t) => t.bufferAmount).reduce((a, b) => a + b, 0) * 100) / 100 : 0;
    const maxHours = !!areaTotal
      ? Math.round(areaTotal.itemTotals.map((t) => t.adjustedRangeHigh).reduce((a, b) => a + b, 0) * 100) / 100
      : 0;

    const totalHours = !!areaTotal
      ? Math.round(areaTotal.itemTotals.map((t) => t.totalImplementerHours).reduce((a, b) => a + b, 0) * 100) / 100
      : 0;

    const totalCost = !!areaTotal ? areaTotal.totalCost : 0;
    const hoursTooltip = (
      <div>
        <div>ABP + Factors = {minHours}</div>
        <div>HP + Factors = {maxHours}</div>
        <div>Buffer = {buffer}</div>
      </div>
    );
    return (
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Grid container>
            <Grid item xs={10}>
              <div className="form-control">
                <Tooltip
                  title={descriptionTooltip}
                  placement={"bottom-start"}
                  disableFocusListener={!this.props.area.description}
                  disableHoverListener={!this.props.area.description}>
                  <TextField
                    placeholder="Area name"
                    className="area-title"
                    value={this.props.area.name}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={this.onNameChange}
                    fullWidth
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="form-control">
                <ConfirmableAction
                  type="IconButton"
                  iconName="comment"
                  noButtons={true}
                  className={`${this.props.area.description ? "has-description" : ""}`}>
                  <div className="wide-editor">
                    <TextField
                      fullWidth
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      value={this.props.area.description}
                      onChange={this.descriptionChanged}
                      label="Description"
                      multiline
                    />
                  </div>
                </ConfirmableAction>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} style={{ display: "flex" }}>
          <div className="area-summary">
            {area.isExcludedFromPlan ? (
              <div className="excluded-message">Excluded from plan </div>
            ) : (
              <Grid container>
                <Grid item xs={6}>
                  <Tooltip title={hoursTooltip}>
                    <span>{totalHours} hours</span>
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <span>${formatter.formatCurrency(totalCost)}</span>
                </Grid>
              </Grid>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={1}>
          <Grid container>
            <Grid item xs="auto">
              <>
                <span ref={this.menuButtonRef}>
                  <IconButton color="inherit" onClick={this.handleMenuClick}>
                    <MoreVertIcon />
                  </IconButton>
                </span>
                <Menu anchorEl={anchorEl} open={open} onClose={this.handleMenuClose}>
                  <MenuItem onClick={this.moveUp}>
                    <ListItemIcon>
                      <ArrowUpwardIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">Move up</Typography>
                  </MenuItem>
                  <MenuItem onClick={this.toggleExclude}>
                    <ListItemIcon>
                      <CancelIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {this.props.area.isExcludedFromPlan ? "Include in plan" : "Exclude from plan"}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={this.deleteArea}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">Delete</Typography>
                  </MenuItem>
                </Menu>
              </>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  private moveUp = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    this.props.moveUp();
    event.stopPropagation();
    this.setState((s) => ({ ...s, anchorEl: null }));
  };

  private deleteArea = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (window?.confirm("Are you sure you want to delete this area?")) {
      this.props.deleteArea();
    }
    event.stopPropagation();
    this.setState((s) => ({ ...s, anchorEl: null }));
  };

  private toggleExclude = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation();
    this.props.toggleExclude();
    this.setState((s) => ({ ...s, anchorEl: null }));
  };

  private handleMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState((s) => ({ ...s, anchorEl: this.menuButtonRef.current }));
    event.stopPropagation();
  };

  private handleMenuClose = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    this.setState((s) => ({ ...s, anchorEl: null }));
    event.stopPropagation();
  };

  private onNameChange = (event: object) => {
    this.props.onNameChange((event as any).target.value);
  };

  private descriptionChanged = (event: object) => {
    this.props.onDescriptionChange((event as any).target.value);
  };
}
