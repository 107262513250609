import { gql } from "@apollo/client";

export const PERSON_DETAILS_FRAGMENT = gql`
  fragment PersonDetailsFields on Person {
    id
    emailAddress
    givenName
    surname
    fullName
    photos {
      uri
      size
    }
  }
`;

export const ESTIMATE_DETAILS_FRAGMENT = gql`
  ${PERSON_DETAILS_FRAGMENT}
  fragment EstimateDetailFields on Estimate {
    id
    name
    description
    client {
      clientId
      name
    }
    tags {
      id
      name
    }
    roles {
      id
      name
      isEnabled
    }
    itemModifiers {
      id
      name
      description
      percentage
      rolesAffected
    }
    areas {
      id
      name
      description
      items {
        id
        name
        description
        roleId
        rangeLow
        rangeHigh
        isGroupTask
      }
      isExcludedFromPlan
    }
    notes
    assumptions
    riskAssessment {
      questions {
        id
        questionText
        answer
        weight
      }
      score
    }
    plan {
      teamMembers {
        id
        type
        name
        cost
        rate
        allocation
        duration
        assignedHours
        roleAssignments
      }
      areaTotals {
        areaId
        itemTotals {
          itemId
          adjustedRangeLow
          adjustedRangeHigh
          spread
          numberOfImplementers
          bufferAmount
          totalImplementerHours
          totalCoordinatorHours
          implementerCost
          coordinatorCost
          cost
        }
        totalCost
      }
      totalCost
      contingency
      discount
      finalPrice
      isValid
      validationMessage
    }
    author {
      ...PersonDetailsFields
    }
    dateCreated
    revisions {
      author {
        ...PersonDetailsFields
      }
      date
      comment
    }
    status
  }
`;

export const ESTIMATE_PUBLIC_DETAILS_FRAGMENT = gql`
  fragment EstimatePublicDetailFields on Estimate {
    id
    name
    description
    client {
      clientId
      name
    }
    tags {
      id
      name
    }
    roles {
      id
      name
      isEnabled
    }
    itemModifiers {
      id
      name
      description
      percentage
      rolesAffected
    }
    areas {
      id
      name
      description
      items {
        id
        name
        description
        roleId
        rangeLow
        rangeHigh
        isGroupTask
      }
      isExcludedFromPlan
    }
    assumptions
    plan {
      teamMembers {
        id
        type
        name
        cost
        rate
        allocation
        duration
        assignedHours
        roleAssignments
      }
      areaTotals {
        areaId
        itemTotals {
          itemId
          adjustedRangeLow
          adjustedRangeHigh
          spread
          numberOfImplementers
          bufferAmount
          totalImplementerHours
          totalCoordinatorHours
          implementerCost
          coordinatorCost
          cost
        }
        totalCost
      }
      totalCost
      contingency
      discount
      finalPrice
      isValid
      validationMessage
    }
    dateCreated
  }
`;
