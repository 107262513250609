import * as React from "react";
import * as ReactDOM from "react-dom";
import { Tooltip, TextField, Select, MenuItem, Checkbox } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import { ConfirmableAction, NumberInput } from "../../misc";
import { SettableEstimateItemProps } from "../models";
import { ConfidenceIndicator } from "./ConfidenceIndicator";
import { EstimateItem, EstimateItemModifier, EstimatePlanTeamMember, EstimateRole } from "../../api/models";

interface ItemEditorProps {
  roles: EstimateRole[];
  itemModifiers: EstimateItemModifier[];
  teamMembers: EstimatePlanTeamMember[];
  item: EstimateItem;
  onNameKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => any;
  setItemProps: (props: SettableEstimateItemProps) => any;
  deleteItem?: () => any;
  startItemMove?: () => any;
  stopItemMove?: () => any;
  completeItemMove?: () => any;
}

export interface ItemEditorState {
  draggingOver: boolean;
}

export class ItemEditor extends React.Component<ItemEditorProps, {}> {
  public state: ItemEditorState = { draggingOver: false };
  private nameInputCellRef = React.createRef<HTMLTableDataCellElement>();

  public shouldComponentUpdate(nextProps: ItemEditorProps, nextState: { draggingOver: boolean }) {
    return (
      nextProps.item !== this.props.item ||
      nextProps.teamMembers !== this.props.teamMembers ||
      this.RoleChangesRequireRerender(nextProps) ||
      this.ItemModifierChangesRequireRerender(nextProps) ||
      this.state.draggingOver !== nextState.draggingOver
    );
  }

  private RoleChangesRequireRerender(nextProps: ItemEditorProps): boolean {
    if (nextProps.roles === this.props.roles) {
      return false;
    } else {
      if (nextProps.roles.length !== this.props.roles.length) {
        return true;
      } else {
        for (let i = 0; i < this.props.roles.length; i++) {
          const current = this.props.roles[i];
          const next = nextProps.roles[i];

          if (current.id !== next.id || current.isEnabled !== next.isEnabled) {
            return true;
          }
        }
      }
      return false;
    }
  }

  private ItemModifierChangesRequireRerender(nextProps: ItemEditorProps): boolean {
    if (nextProps.itemModifiers === this.props.itemModifiers) {
      return false;
    } else {
      if (nextProps.itemModifiers.length !== this.props.itemModifiers.length) {
        return true;
      } else {
        for (let i = 0; i < this.props.itemModifiers.length; i++) {
          const current = this.props.itemModifiers[i];
          const next = nextProps.itemModifiers[i];

          if (current.id !== next.id || current.percentage !== next.percentage) {
            return true;
          }
        }
      }
      return false;
    }
  }

  public render() {
    const low = this.props.item.rangeLow;
    const high = this.props.item.rangeHigh;

    const lowGreaterThanHigh = low != null && high != null && low > high;

    const numberOfTeamMembersInrole = this.props.teamMembers.filter(
      (m) => m.roleAssignments.indexOf(this.props.item.roleId || "") >= 0
    ).length;

    const descriptionTooltip = <pre>{this.props.item.description}</pre>;

    return (
      <tr
        className={`item-editor ${this.props.item.name ? "" : "name-missing"} ${this.state.draggingOver ? "dragging-over" : ""} ${
          !!this.props.startItemMove ? "draggable" : ""
        }`}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}>
        <td className="confidence-value-cell">
          <span
            draggable={!!this.props.startItemMove}
            className="drag-handle"
            onDragStart={this.onDragItemStart}
            onDragEnd={this.onDragItemEnd}>
            ...
          </span>
          <ConfidenceIndicator item={this.props.item} />
        </td>
        <td className="name-value-cell" ref={this.nameInputCellRef}>
          <Tooltip
            title={descriptionTooltip}
            placement={"bottom-start"}
            disableFocusListener={!this.props.item.description}
            disableHoverListener={!this.props.item.description}>
            <TextField
              value={this.props.item.name}
              onChange={this.nameChanged}
              InputProps={{ onKeyDown: this.props.onNameKeyDown }}
              fullWidth
              placeholder="Item Name"
            />
          </Tooltip>
        </td>
        <td className="description-value-cell">
          <ConfirmableAction
            type="IconButton"
            iconName="comment"
            noButtons={true}
            className={`${this.props.item.description ? "has-description" : ""}`}>
            <div className="wide-editor">
              <TextField fullWidth value={this.props.item.description} onChange={this.descriptionChanged} label="Description" multiline />
            </div>
          </ConfirmableAction>
        </td>
        <td className="task-type-cell">
          <Select value={this.props.item.roleId || ""} fullWidth onChange={this.roleIdChanged} placeholder="Task type">
            {this.props.roles.map((r) => {
              return r.isEnabled ? (
                <MenuItem key={r.id} value={r.id} selected={this.props.item.roleId === r.id} onChange={this.roleIdChanged}>
                  {r.name}
                </MenuItem>
              ) : null;
            })}
          </Select>
        </td>
        <td className="group-task-cell">
          <Checkbox
            checked={this.props.item.isGroupTask}
            onChange={this.isGroupTaskChanged}
            color="primary"
            icon={<PersonIcon />}
            checkedIcon={<GroupIcon />}
          />
          {this.props.item.isGroupTask ? <span className="team-member-count">x{numberOfTeamMembersInrole}</span> : null}
        </td>
        <td className={`range-value-cell highlight`}>
          <NumberInput className="range-value" value={low} fullWidth error={lowGreaterThanHigh} onChange={this.lowChanged} />
        </td>
        <td className={`range-value-cell highlight`}>
          <NumberInput className="range-value" value={high} fullWidth error={lowGreaterThanHigh} onChange={this.highChanged} />
        </td>
        <td className="action-cell">
          {this.props.deleteItem ? (
            <ConfirmableAction type="IconButton" action={this.props.deleteItem} iconName="delete" className="delete-btn">
              <span>Are you sure you want to delete this item?</span>
            </ConfirmableAction>
          ) : null}
        </td>
      </tr>
    );
  }

  public requestFocus = () => {
    const inputs = (ReactDOM.findDOMNode(this.nameInputCellRef.current) as Element).getElementsByTagName(
      "input"
    ) as HTMLCollectionOf<HTMLInputElement>;
    if (inputs.length === 1) {
      const input = inputs[0];
      input.focus();

      setTimeout(() => {
        input.selectionStart = input.selectionEnd = 10000;
      }, 0);
    }
  };

  private nameChanged = (event: any) => {
    const value = event.target.value as string;
    const props = {
      ...this.props.item,
      name: value
    };
    this.props.setItemProps(props);
  };

  private descriptionChanged = (event: any) => {
    const value = event.target.value as string;
    const props = {
      ...this.props.item,
      description: value
    } as SettableEstimateItemProps;
    this.props.setItemProps(props);
  };

  private roleIdChanged = (event: object) => {
    const roleId = (event as any).target.value;
    const props = {
      ...this.props.item,
      roleId
    };
    this.props.setItemProps(props);
  };

  private isGroupTaskChanged = (event: object, checked: boolean) => {
    const props = {
      ...this.props.item,
      isGroupTask: checked
    };
    this.props.setItemProps(props);
  };

  private lowChanged = (value: number | null) => {
    const props = {
      ...this.props.item,
      rangeLow: value
    };
    this.props.setItemProps(props);
  };

  private highChanged = (value: number | null) => {
    const props = {
      ...this.props.item,
      rangeHigh: value
    };
    this.props.setItemProps(props);
  };

  private onDragItemStart = (e: React.DragEvent<HTMLTableRowElement>) => {
    if (this.props.startItemMove) {
      this.props.startItemMove();
    }
  };

  private onDragItemEnd = (e: React.DragEvent<HTMLTableRowElement>) => {
    if (this.props.stopItemMove) {
      this.props.stopItemMove();
    }
  };

  private onDragOver = (e: React.DragEvent<HTMLTableRowElement>) => {
    e.preventDefault();
  };

  private onDrop = (e: React.DragEvent<HTMLTableRowElement>) => {
    this.setState({ draggingOver: false });
    if (this.props.completeItemMove) {
      this.props.completeItemMove();
    }
  };

  private onDragEnter = (e: React.DragEvent<HTMLTableRowElement>) => {
    this.setState({ draggingOver: true });
  };

  private onDragLeave = (e: React.DragEvent<HTMLTableRowElement>) => {
    this.setState({ draggingOver: false });
  };
}
