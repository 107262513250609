import * as React from "react";
import { useParams } from "react-router-dom";
import { TextField, Button } from "@material-ui/core";
import { Markdown } from "../misc";
import { ContentContainer, ConstrainedPageContent, FormSection, Page, PageContent, PageSection } from "../layout";
import { EstimatePublicHeader, EstimatePublicSummary } from "../estimates/public";
import { FormField, FormControl } from "../layout";
import { EstimatePublic } from "../api/models";
import { GET_ESTIMATE_PUBLIC } from "../api/GraphQL/queries";
import { useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { EstimateHeaderPlaceholder } from "../estimates/detail";

const EstimateDetailPage: React.FunctionComponent = (props) => {
  const { key } = useParams<{ key: string }>();
  const isSecuredWithPassword = !key.startsWith("np-");

  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  const [getEstimate, { loading: isFetching, data }] = useLazyQuery<{ estimatePublic: EstimatePublic }, { key: string; password: string }>(
    GET_ESTIMATE_PUBLIC
  );

  const formik = useFormik({
    initialValues: {
      password: ""
    },
    validate: (value) => {
      const errors: { [key: string]: string } = {};
      return errors;
    },
    onSubmit: (value) => {
      getEstimate({
        variables: {
          key,
          password: value.password
        }
      });
    }
  });

  React.useEffect(() => {
    if (!isSecuredWithPassword) {
      getEstimate({
        variables: {
          key: key.substr(3),
          password: ""
        }
      });
    }
  }, [key, isSecuredWithPassword, getEstimate]);

  const estimate = data?.estimatePublic;

  if (!estimate && isSecuredWithPassword) {
    return (
      <div>
        <ConstrainedPageContent>
          <ContentContainer elevation={0}>
            <form
              onSubmit={(e) => {
                setSubmitAttempted(true);
                formik.handleSubmit(e);
              }}>
              <FormSection title="Access Verification" fullWidth>
                <FormField>
                  <FormControl>
                    <TextField
                      name="password"
                      fullWidth
                      label="enter password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={submitAttempted && !!formik.errors.password}
                      helperText={submitAttempted ? formik.errors.password : undefined}
                    />
                  </FormControl>
                </FormField>
                <FormField>
                  <FormControl>
                    <Button type="submit" disabled={isFetching} variant="contained" color="primary">
                      {isFetching ? "Verifying" : "Verify"}
                    </Button>
                  </FormControl>
                </FormField>
              </FormSection>
            </form>
          </ContentContainer>
        </ConstrainedPageContent>
      </div>
    );
  }

  return (
    <Page>
      <PageContent>
        {estimate ? <EstimatePublicHeader isSticky={false} estimate={estimate} /> : <EstimateHeaderPlaceholder />}
        <ConstrainedPageContent>
          <ContentContainer elevation={0}>
            {estimate && (
              <>
                <PageSection>
                  <Markdown children={estimate.description} />
                </PageSection>
                {!!estimate.assumptions ? (
                  <PageSection title="Assumptions">
                    <Markdown children={estimate.assumptions} />
                  </PageSection>
                ) : null}

                <PageSection title="Items">
                  <EstimatePublicSummary estimate={estimate} />
                </PageSection>
              </>
            )}
          </ContentContainer>
        </ConstrainedPageContent>
      </PageContent>
    </Page>
  );
};

export default EstimateDetailPage;
