import { EstimateClient, EstimateStatus, PersonDetail } from "./GraphQL/models";

export interface AppSettings {
  appUrl: string;
  apiUrl: string;
  appInsightsKey: string;
  azureAdSettings: AzureAdSettings;
}

export interface AzureAdSettings {
  authority: string;
  clientId: string;
  scopes: string;
  validIssuer: string;
}

export interface ApplicationUser {
  id: string;
  access_token?: string;
  username: string;
  fullName: string;
  emailAddress: string;
  // token_expiry: number;
  // issued_at: number;
  // issuer: string;
  // audience: string;
  // roles: string[];
  // roleDict: { [name: string]: boolean };
  avatarColor: colorVariant;
}

export interface PagedResult<T> {
  results: T[];
  hasMoreResults: boolean;
  continuationToken: string | null;
}

export interface Estimate {
  id: string | null;
  name: string;
  description: string;
  client: EstimateClient | null;
  tags: EstimateTag[];
  status: EstimateStatus;
  roles: EstimateRole[];
  itemModifiers: EstimateItemModifier[];
  areas: EstimateArea[];
  notes: string;
  assumptions: string;
  riskAssessment: EstimateRiskAssessment;
  plan: EstimatePlan;

  author: Partial<PersonDetail> | null;
  dateCreated: Date;

  revisions: EstimateRevision[];
}

export type EstimatePublic = Pick<
  Estimate,
  "id" | "name" | "description" | "client" | "tags" | "roles" | "itemModifiers" | "areas" | "assumptions" | "plan" | "dateCreated"
>;

export interface EstimateTag {
  id: string;
  name: string;
}

export interface EstimateRole {
  id: string;
  name: string;
  isEnabled: boolean;
}

export interface EstimateArea {
  id: string;
  name: string;
  description: string;
  isExcludedFromPlan: boolean;
  items: EstimateItem[];
}

export interface EstimateItem {
  id: string;
  name: string;
  description: string;
  roleId: string | null;

  /* Only the raw amounts input by user */
  rangeLow: number | null;
  rangeHigh: number | null;

  isGroupTask: boolean;
}

export interface EstimateItemModifier {
  id: string;
  name: string;
  description: string;
  percentage: number;
  rolesAffected: string[];
}

export interface EstimateRiskAssessment {
  questions: EstimateRiskAssessmentQuestion[];
  score: number;
}

export interface EstimateRiskAssessmentQuestion {
  id: string;
  questionText: string;
  answer: EstimateRiskAssessmentQuestionAnswer;
  weight: EstimateRiskAssessmentQuestionWeight;
}

export enum EstimateRiskAssessmentQuestionAnswer {
  NO = "NO",
  YES = "YES"
}

export const getEstimateRiskAssessmentQuestionAnswerValue = (enumVal: EstimateRiskAssessmentQuestionAnswer): number => {
  switch (enumVal) {
    case EstimateRiskAssessmentQuestionAnswer.NO:
      return 0;
    case EstimateRiskAssessmentQuestionAnswer.YES:
      return 1;
  }
  return 0;
};

export enum EstimateRiskAssessmentQuestionWeight {
  NONE = "NONE",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH"
}

export const getEstimateRiskAssessmentQuestionWeightValue = (enumVal: EstimateRiskAssessmentQuestionWeight): number => {
  switch (enumVal) {
    case EstimateRiskAssessmentQuestionWeight.NONE:
      return 0;
    case EstimateRiskAssessmentQuestionWeight.LOW:
      return 1;
    case EstimateRiskAssessmentQuestionWeight.MEDIUM:
      return 2;
    case EstimateRiskAssessmentQuestionWeight.HIGH:
      return 3;
  }
  return 0;
};

export interface EstimatePlan {
  teamMembers: EstimatePlanTeamMember[];

  areaTotals: EstimatePlanAreaTotal[];

  totalCost: number;
  contingency: number;
  discount: number;
  finalPrice: number;
  isValid: boolean;
  validationMessage: string;
}
export interface EstimatePlanTeamMember {
  id: string;
  name: string;
  type: EstimatePlanTeamMemberType;
  cost: number;
  rate: number;
  allocation: number;
  duration: number;
  roleAssignments: string[];
  assignedHours: number;
}

export enum EstimatePlanTeamMemberType {
  IMPLEMENTER = "IMPLEMENTER",
  COORDINATOR = "COORDINATOR"
}
export interface EstimatePlanAreaTotal {
  areaId: string;
  itemTotals: EstimatePlanItemTotal[];
  totalCost: number;
}

export interface EstimatePlanItemTotal {
  itemId: string;

  /* includes adjustments from modifiers */
  adjustedRangeLow: number;
  adjustedRangeHigh: number;

  numberOfImplementers: number;
  spread: number;

  bufferAmount: number;

  totalImplementerHours: number;
  totalCoordinatorHours: number;

  implementerCost: number;
  coordinatorCost: number;

  /* we need to make an assumption about who will do the work to determine cost */
  cost: number;
}

export interface EstimateRevision {
  author: Partial<PersonDetail>;
  date: Date;
  comment: string;
}

export type colorVariant = "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "10" | "11" | "12" | "13" | "14" | "15" | "16";
