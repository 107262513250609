import * as React from "react";
import { Grid, Divider, Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { TagChip, TagList } from "../../misc";
import { formatter } from "../../utils/formatter";
import { EstimatePlanTeamMember, EstimateRole } from "../../api/models";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    teamMember: {
      marginBottom: 25
    },
    name: {
      fontSize: 16,
      lineHeight: "24px"
    },
    hours: {
      fontSize: 14,
      lineHeight: "24px",
      textAlign: "right",
      fontWeight: 400,

      "&.no-hours": {
        color: theme.palette.error.main
      }
    },
    rate: {
      fontSize: 14,
      lineHeight: "24px",
      fontWeight: 200,
      textAlign: "right"
    }
  });

interface TeamMemberListProps extends WithStyles<typeof styles> {
  teamMembers: EstimatePlanTeamMember[];
  roles: EstimateRole[];
}

class TeamMemberList extends React.PureComponent<TeamMemberListProps> {
  public render() {
    return (
      <div className="team-members">
        {this.props.teamMembers.map((member) => {
          return (
            <Grid container spacing={0} key={member.id} className={this.props.classes.teamMember}>
              <Grid item xs={12} sm={8} md={10}>
                <span className={this.props.classes.name}>{member.name}</span>
              </Grid>
              <Grid item xs={3} sm={2} md={1}>
                <span className={this.props.classes.rate}>
                  ${formatter.formatCurrency(member.rate)}
                  /hr
                </span>
              </Grid>
              <Grid item xs={3} sm={2} md={1}>
                <div className={`${this.props.classes.hours} ${member.assignedHours === 0 ? "no-hours" : ""}`}>
                  {Math.round(member.assignedHours * 10) / 10} hrs
                </div>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <div>
                  {member.roleAssignments.length ? (
                    <TagList tagNames={member.roleAssignments.map((id) => this.getRoleName(id) || "").filter((m) => m !== "")} />
                  ) : (
                    <TagChip name="Project support" outline />
                  )}
                </div>
              </Grid>
            </Grid>
          );
        })}
      </div>
    );
  }

  private getRoleName = (id: string): string | undefined => {
    const role = this.props.roles.find((r) => r.id === id);
    return role?.name;
  };
}

export default withStyles(styles)(TeamMemberList);
