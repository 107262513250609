import * as React from "react";
import { Accordion, AccordionSummary, Grid, AccordionDetails, TextField, Select, MenuItem, Typography } from "@material-ui/core";
import ExpandIcon from "@material-ui/icons/ExpandMore";
import { ConfirmableAction, TagsField, TagValue, TagList, NumberInput } from "../../misc";
import { Form, FormSection, FormField } from "../../layout";
import { SettableEstimatePlanTeamMemberProps } from "../models";
import { formatter } from "../../utils/formatter";
import { EstimatePlanTeamMember, EstimatePlanTeamMemberType, EstimateRole } from "../../api/models";

export interface TeamMemberEditorProps {
  member: EstimatePlanTeamMember;
  duration: number;
  roles: EstimateRole[];
  relativeUtilization: number;

  setTeamMemberProps: (teamMemberId: string, props: SettableEstimatePlanTeamMemberProps) => any;
  deleteTeamMember: (teamMemberId: string) => any;
}

interface TeamMemberEditorState {
  roles: EstimateRole[];
  roleTags: TagValue[];
  roleNames: { [id: string]: string };
}

export class TeamMemberEditor extends React.PureComponent<TeamMemberEditorProps, TeamMemberEditorState> {
  public state: TeamMemberEditorState = { roles: [], roleTags: [], roleNames: {} };

  public static getDerivedStateFromProps(nextProps: TeamMemberEditorProps, prevState: TeamMemberEditorState) {
    return nextProps.roles !== prevState.roles
      ? (() => {
          const roleNames: { [key: string]: string } = {};
          return {
            ...prevState,
            roleTags: nextProps.roles.map((role) => {
              roleNames[role.id] = role.name;
              return { value: role.id, label: role.name };
            }),
            roleNames
          };
        })()
      : prevState;
  }

  public render() {
    const { member, duration, relativeUtilization } = this.props;

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandIcon />}>
          <Grid container spacing={1}>
            <Grid item xs={10} sm={3} style={{ display: "flex" }}>
              <Typography variant="h6">{member.name}</Typography>
            </Grid>
            <Grid item xs={2} sm={2} className="cost-cell">
              <span className="team-member-summary-col">
                {" "}
                ${formatter.formatCurrency(member.rate)}
                /hr
              </span>
            </Grid>
            <Grid item xs={6} sm={4}>
              {member.type === EstimatePlanTeamMemberType.IMPLEMENTER ? (
                <TagList tagNames={member.roleAssignments.map((id) => this.state.roleNames[id])} />
              ) : (
                <span className="team-member-summary-col">Duration-based</span>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <span className="team-member-summary-col">
                <span>
                  {member.allocation}% for {duration.toFixed(1)} wks
                </span>{" "}
                <span>({member.assignedHours.toFixed(0)} hrs)</span>
              </span>
              <div className="relative-duration-bar-wrapper">
                <div className="relative-duration-bar" style={{ width: `${relativeUtilization}%` }} />
              </div>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Form fullWidth>
            <FormSection>
              <FormField>
                <TextField label="Name" value={member.name} onChange={this.onTeamMemberPropsChanged(member, "name")} />
              </FormField>
              <FormField>
                <Select
                  value={member.type || EstimatePlanTeamMemberType.IMPLEMENTER}
                  onChange={this.onTeamMemberPropsChanged(member, "type")}>
                  <MenuItem value={EstimatePlanTeamMemberType.IMPLEMENTER}>Task-based</MenuItem>
                  <MenuItem value={EstimatePlanTeamMemberType.COORDINATOR}>Duration-based</MenuItem>
                </Select>
              </FormField>
              <FormField>
                {member.type === EstimatePlanTeamMemberType.IMPLEMENTER ? (
                  <TagsField
                    value={member.roleAssignments}
                    tags={this.state.roleTags}
                    onValueChanged={this.onRoleAssignmentsChanged(member)}
                  />
                ) : null}
              </FormField>
              <FormField>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <NumberInput
                      label="Allocation"
                      value={member.allocation}
                      onChange={this.onTeamMemberNumberPropsChanged(member, "allocation")}
                      endAdornment={<span>%</span>}
                    />
                  </Grid>

                  {member.type === EstimatePlanTeamMemberType.COORDINATOR ? (
                    <Grid item xs={6}>
                      {" "}
                      <NumberInput
                        label="Duration"
                        value={member.duration}
                        onChange={this.onTeamMemberNumberPropsChanged(member, "duration")}
                        endAdornment={<span>weeks</span>}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </FormField>
              <FormField>
                <NumberInput
                  label="Rate"
                  value={member.rate}
                  onChange={this.onTeamMemberNumberPropsChanged(member, "rate")}
                  startAdornment={<span>$</span>}
                />
              </FormField>
              <FormField>
                <ConfirmableAction
                  action={this.deleteTeamMember(member.id)}
                  type="Button"
                  buttonVariant="contained"
                  buttonLabel="Remove this team member"
                  className="delete-team-member">
                  <span>Are you sure you want to delete this team member?</span>
                </ConfirmableAction>
              </FormField>
            </FormSection>
          </Form>
        </AccordionDetails>
      </Accordion>
    );
  }

  private onTeamMemberPropsChanged = (member: EstimatePlanTeamMember, propName: string) => (event: object) => {
    const newProps = {
      ...member,
      [propName]: (event as any).target.value
    } as SettableEstimatePlanTeamMemberProps;

    this.props.setTeamMemberProps(member.id, newProps);
  };

  private onTeamMemberNumberPropsChanged = (member: EstimatePlanTeamMember, propName: string) => (value: number | null) => {
    const newProps = {
      ...member,
      [propName]: value
    } as SettableEstimatePlanTeamMemberProps;

    this.props.setTeamMemberProps(member.id, newProps);
  };

  private onRoleAssignmentsChanged = (member: EstimatePlanTeamMember) => (selectedTags: string[]) => {
    const newProps = {
      ...member,
      roleAssignments: selectedTags
    } as SettableEstimatePlanTeamMemberProps;

    this.props.setTeamMemberProps(member.id, newProps);
  };

  private deleteTeamMember = (teamMemberId: string) => () => {
    this.props.deleteTeamMember(teamMemberId);
  };
}
