import * as React from "react";
import { Prompt, Redirect, useParams } from "react-router-dom";
import { Fab, Tabs, Tab, Fade, Paper } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import InfoIcon from "@material-ui/icons/Info";
import GroupWork from "@material-ui/icons/GroupWork";
import ErrorIcon from "@material-ui/icons/Error";
import NoteIcon from "@material-ui/icons/NoteOutlined";
import UserIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import MoreIcon from "@material-ui/icons/MoreOutlined";
import { EstimateHeader, EstimateHeaderPlaceholder } from "../estimates/detail";
import { ConstrainedPageContent, Page, PageContent, TabSection } from "../layout";
import { EffortForm } from "../estimates/editor/EffortForm.1";
import { InfoForm } from "../estimates/editor/InfoForm";
import { NotesForm } from "../estimates/editor/NotesForm";
import { PlanModifiersForm } from "../estimates/editor/PlanModifiersForm";
import { RiskAssessmentForm } from "../estimates/editor/RiskAssessmentForm";
import { TeamForm } from "../estimates/editor/TeamForm";
import {
  BulkImport,
  SettableEstimateAreaProps,
  SettableEstimateItemModifierProps,
  SettableEstimateItemProps,
  SettableEstimatePlanTeamMemberProps,
  SettableEstimateProps,
  SettableRoleProps
} from "../estimates/models";
import { EstimateSaveDialog } from "../estimates/editor/EstimateSaveDialog";
import { Estimate, EstimateRiskAssessmentQuestionAnswer } from "../api/models";
import { useLookupData } from "../lookupData/LookupDataProvider";
import { useUserContext } from "../auth/UserContextProvider";
import { estimateEditorReducer, pristineChangeLog } from "../estimates/estimateEditorReducer";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ESTIMATE } from "../api/GraphQL/queries";
import { DELETE_ESTIMATE, UPSERT_ESTIMATE } from "../api/GraphQL/mutations";
import { useNotifications } from "../notifications/NotificationsProvider";
import { Alert } from "@material-ui/lab";

type EditorTabValue = "About" | "Effort" | "RiskAssessment" | "NotesAndAssumptions" | "Team" | "Plan";

const EstimateEditorPage: React.FunctionComponent = () => {
  const [isSaveDialogOpen, setIsSaveDialogOpen] = React.useState(false);
  const { id } = useParams<{ id: string }>();
  const { user } = useUserContext();
  const { setNotification } = useNotifications();
  const [selectedTab, setSelectedTab] = React.useState<EditorTabValue>("About");
  const { tags } = useLookupData();
  const [itemInTransit, setItemInTransit] = React.useState<{ areaId: string; itemId: string }>();
  const [estimateDeleted, setEstimateDeleted] = React.useState(false);

  const [editorState, dispatch] = React.useReducer(estimateEditorReducer, {
    estimate: null,
    changeLog: pristineChangeLog,
    isDirty: false
  });

  const { data, loading: isFetching } = useQuery<{ estimate: Estimate }>(GET_ESTIMATE, {
    variables: {
      id
    }
  });

  React.useEffect(() => {
    if (!!data?.estimate) {
      dispatch({ type: "RESET_EDITOR", estimate: data?.estimate });
      window.scroll({
        top: 0
      });
    }
  }, [data?.estimate]);

  const confirmSave = () => {
    setIsSaveDialogOpen(true);
  };

  const updateMetadata = (name: keyof SettableEstimateProps) => (value: any) => {
    dispatch({
      type: "SET_ESTIMATE_PROP",
      prop: name,
      value
    });
  };

  const addTeamMember = () => {
    dispatch({
      type: "ADD_TEAM_MEMBER"
    });
  };

  const deleteTeamMember = (teamMemberId: string) => {
    dispatch({
      type: "DELETE_TEAM_MEMBER",
      teamMemberId
    });
  };

  const setTeamMemberProps = (teamMemberId: string, props: SettableEstimatePlanTeamMemberProps) => {
    dispatch({
      type: "SET_TEAM_MEMBER_PROPS",
      teamMemberId,
      props
    });
  };

  const setPlanModifiers = (contingency: number, discount: number) => {
    dispatch({
      type: "SET_PLAN_MODIFIERS",
      contingency,
      discount
    });
  };

  const addArea = () => {
    dispatch({
      type: "ADD_AREA"
    });
  };

  const setAreaProps = (areaId: string, props: SettableEstimateAreaProps) => {
    dispatch({
      type: "SET_AREA_PROPS",
      areaId,
      props
    });
  };

  const moveAreaUp = (areaId: string) => {
    dispatch({
      type: "MOVE_AREA_UP",
      areaId
    });
  };

  const deleteArea = (areaId: string) => {
    dispatch({
      type: "DELETE_AREA",
      areaId
    });
  };

  const addItem = (areaId: string) => {
    dispatch({
      type: "ADD_ITEM",
      areaId
    });
  };

  const deleteItem = (areaId: string, itemId: string) => {
    dispatch({
      type: "DELETE_ITEM",
      areaId,
      itemId
    });
  };

  const setItemProps = (areaId: string, itemId: string, props: SettableEstimateItemProps) => {
    dispatch({
      type: "SET_ITEM_PROPS",
      areaId,
      itemId,
      props
    });
  };

  const setRiskAssessmentAnswer = (questionId: string, answer: EstimateRiskAssessmentQuestionAnswer) => {
    dispatch({
      type: "SET_RISK_ASSESSMENT_ANSWER",
      questionId,
      answer
    });
  };

  const setRoleProps = (roleId: string, props: SettableRoleProps) => {
    dispatch({ type: "SET_ROLE_PROPS", roleId, props });
  };

  const startItemMove = (areaId: string, itemId: string) => {
    setItemInTransit({ areaId, itemId });
  };

  const stopItemMove = () => {
    setItemInTransit(undefined);
  };

  const completeItemMove = (areaId: string, itemId: string) => {
    if (itemInTransit) {
      dispatch({
        type: "MOVE_ITEM",
        itemId: itemInTransit.itemId,
        fromAreaId: itemInTransit.areaId,
        toAreaId: areaId,
        nearItemId: itemId
      });
      setItemInTransit(undefined);
    }
  };

  const addItemModifier = () => {
    dispatch({ type: "ADD_ITEM_MODIFIER" });
  };

  const deleteItemModifier = (modifierId: string) => {
    dispatch({ type: "DELETE_ITEM_MODIFIER", modifierId });
  };

  const setItemModifierProps = (modifierId: string, props: SettableEstimateItemModifierProps) => {
    dispatch({ type: "SET_ITEM_MODIFIER_PROPS", modifierId, props });
  };

  const bulkImport = (bulkImport: BulkImport) => {
    dispatch({ type: "BULK_IMPORT", bulkImport });
  };

  const [upsertEstimate, { loading: isSaving }] = useMutation<{ estimateUpsert: Estimate }>(UPSERT_ESTIMATE, {
    onCompleted: (mutationResponse) => {
      setIsSaveDialogOpen(false);
      setNotification("Estimate saved", "success");
    }
  });

  const [deleteEstimate] = useMutation<{ estimateDelete: { id: string; succeeded: boolean; message: string } }, { id: string }>(
    DELETE_ESTIMATE,
    {
      onCompleted: (mutationResponse) => {
        if (mutationResponse.estimateDelete.succeeded) {
          setEstimateDeleted(true);
        }
      },
      update: (cache, mutationResponse) => {
        cache.modify({
          fields: {
            estimates(existingItems, { readField, toReference }) {
              return mutationResponse.data?.estimateDelete.succeeded && mutationResponse.data?.estimateDelete.id
                ? {
                    ...existingItems,
                    edges: existingItems.edges.filter((edge: any) => {
                      return readField("id", edge.node) !== mutationResponse.data?.estimateDelete.id;
                    })
                  }
                : existingItems;
            }
          }
        });
      }
    }
  );

  const onDeleteEstimate = async () => {
    if (estimate?.id) {
      if (window.confirm("Are you sure you want to delete this estimate?")) {
        deleteEstimate({ variables: { id: estimate.id } });
      }
    }
  };

  const onSave = async (revisionText: string) => {
    const newEstimate: Estimate = {
      ...editorState.estimate!,
      author: editorState.estimate!.author
        ? {
            id: editorState.estimate!.author.id,
            fullName: editorState.estimate!.author.fullName
          }
        : null,
      revisions: editorState
        .estimate!.revisions.filter((f) => !!f.author)
        .map((r) => ({
          ...r,
          author: {
            id: r.author.id,
            fullName: r.author.fullName
          }
        }))
        .concat({
          author: {
            id: user!.person.id,
            fullName: user!.person.fullName
          },
          date: new Date(),
          comment: revisionText
        })
    };

    upsertEstimate({
      variables: { estimate: JSON.parse(JSON.stringify(newEstimate), (key: any, value: any) => (key === "__typename" ? undefined : value)) }
    });
    setIsSaveDialogOpen(false);
  };

  const onCancel = () => {
    setIsSaveDialogOpen(false);
  };

  const handleTabChange = (event: any, value: EditorTabValue) => {
    setSelectedTab(value);
  };

  const estimate = editorState.estimate;

  if (estimateDeleted) {
    return <Redirect to={"/"} />;
  }

  if (isFetching && !estimate) {
    return <EstimateHeaderPlaceholder />;
  }

  if (!estimate) {
    return null;
  }

  return (
    <>
      <Page>
        <Prompt when={editorState.isDirty} message="You have unsaved changes. Are you sure you want to leave?" />
        <div className="floating-action-button">
          <Fab variant="circular" color="primary" disabled={isFetching || isSaving || !editorState.isDirty} onClick={confirmSave}>
            <SaveIcon />
          </Fab>
        </div>
        <PageContent>
          <EstimateHeader estimate={estimate} onDeleteRequested={onDeleteEstimate} hasPendingChanges={editorState.isDirty} />
          <Fade in>
            <ConstrainedPageContent>
              <Paper square>
                <Tabs value={selectedTab} onChange={handleTabChange}>
                  <Tab icon={<InfoIcon />} label="About" value="About" />
                  <Tab icon={<GroupWork />} label="Effort" value="Effort" />
                  <Tab icon={<ErrorIcon />} label="Risk" value="RiskAssessment" />
                  <Tab icon={<NoteIcon />} label="Notes" value="NotesAndAssumptions" />
                  <Tab icon={<UserIcon />} label="Team" value="Team" />
                  <Tab icon={<MoreIcon />} label="Plan" value="Plan" />
                </Tabs>
              </Paper>
              {!estimate.plan.isValid ? <Alert severity="error">{estimate.plan.validationMessage}</Alert> : null}
              <TabSection active={selectedTab === "About"}>
                <InfoForm
                  name={estimate.name}
                  description={estimate.description}
                  client={estimate.client}
                  tags={estimate.tags}
                  availableTags={tags || []}
                  nameChanged={updateMetadata("name")}
                  descriptionChanged={updateMetadata("description")}
                  clientChanged={updateMetadata("client")}
                  tagsChanged={updateMetadata("tags")}
                />
              </TabSection>
              <TabSection active={selectedTab === "Team"}>
                <TeamForm
                  roles={estimate.roles}
                  teamMembers={estimate.plan.teamMembers}
                  addTeamMember={addTeamMember}
                  deleteTeamMember={deleteTeamMember}
                  setTeamMemberProps={setTeamMemberProps}
                />
              </TabSection>
              <TabSection active={selectedTab === "Plan"}>
                <PlanModifiersForm
                  contingency={estimate.plan.contingency}
                  discount={estimate.plan.discount}
                  setModifiers={setPlanModifiers}
                />
              </TabSection>
              <TabSection active={selectedTab === "Effort"}>
                <EffortForm
                  estimate={estimate}
                  roles={estimate.roles}
                  teamMembers={estimate.plan.teamMembers}
                  itemModifiers={estimate.itemModifiers}
                  areas={estimate.areas}
                  areaTotals={estimate.plan.areaTotals}
                  addArea={addArea}
                  deleteArea={deleteArea}
                  setAreaProps={setAreaProps}
                  moveAreaUp={moveAreaUp}
                  addItem={addItem}
                  deleteItem={deleteItem}
                  setItemProps={setItemProps}
                  startItemMove={startItemMove}
                  stopItemMove={stopItemMove}
                  completeItemMove={completeItemMove}
                  bulkImport={bulkImport}
                  addItemModifier={addItemModifier}
                  deleteItemModifier={deleteItemModifier}
                  setItemModifierProps={setItemModifierProps}
                  setRoleProps={setRoleProps}
                />
              </TabSection>
              <TabSection active={selectedTab === "RiskAssessment"}>
                <RiskAssessmentForm riskAssessment={estimate.riskAssessment} setRiskAssessmentAnswer={setRiskAssessmentAnswer} />
              </TabSection>
              <TabSection active={selectedTab === "NotesAndAssumptions"}>
                <NotesForm
                  notes={estimate?.notes || ""}
                  setNotes={updateMetadata("notes")}
                  assumptions={estimate.assumptions}
                  setAssumptions={updateMetadata("assumptions")}
                />
              </TabSection>
            </ConstrainedPageContent>
          </Fade>
        </PageContent>
      </Page>
      <EstimateSaveDialog
        isOpen={isSaveDialogOpen}
        isFetching={isSaving}
        estimate={estimate}
        changeLog={editorState.changeLog}
        onSave={onSave}
        onCancel={onCancel}
        onStatusChanged={updateMetadata("status")}
      />
    </>
  );
};

export default EstimateEditorPage;
