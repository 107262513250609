import * as React from "react";
import { Redirect } from "react-router-dom";
import { Estimate } from "../api/models";
import { ContentContainer, ConstrainedPageContent, Page, PageContent, SimpleHeader, PageSection } from "../layout";
import { EstimateCreateForm } from "../estimates/create";
import { createEmptyEstimate } from "../estimates/estimateEditorReducer";
import { useNotifications } from "../notifications/NotificationsProvider";
import { gql, useMutation } from "@apollo/client";
import { UPSERT_ESTIMATE } from "../api/GraphQL/mutations";
import { useUserContext } from "../auth/UserContextProvider";
import { ESTIMATE_DETAILS_FRAGMENT } from "../api/GraphQL/fragments";

const EstimateCreatePage: React.FunctionComponent = (props) => {
  const [estimate, setEstimate] = React.useState(createEmptyEstimate());
  const [estimateSaved, setEstimateSaved] = React.useState(false);
  const { setNotification } = useNotifications();
  const { user } = useUserContext();

  const [upsertEstimate, { error: upsertEstimateError, loading: isSaving }] = useMutation<{ estimateUpsert: Estimate }>(UPSERT_ESTIMATE, {
    onCompleted: (data) => {
      if (data.estimateUpsert.id) {
        setEstimate(data.estimateUpsert);
        setEstimateSaved(true);
        setNotification("Estimate saved", "success");
      }
    },
    update: (cache, mutationResponse) => {
      cache.modify({
        fields: {
          estimates(existingItems) {
            const newItemRef = cache.writeFragment({
              data: mutationResponse.data?.estimateUpsert,
              fragment: gql`
                ${ESTIMATE_DETAILS_FRAGMENT}
              `,
              fragmentName: "EstimateDetailFields"
            });
            return !!mutationResponse.data?.estimateUpsert
              ? {
                  ...existingItems,
                  edges: [
                    {
                      node: newItemRef
                    },
                    ...existingItems.edges
                  ]
                }
              : existingItems;
          }
        }
      });
    }
  });

  const onSaveRequested = (newEstimate: Estimate) => {
    const fullEstimate: Estimate = {
      ...newEstimate,
      author: {
        id: user?.id,
        fullName: user?.person.fullName
      },
      revisions: []
    };
    upsertEstimate({
      variables: {
        estimate: fullEstimate
      }
    });
  };

  React.useEffect(() => {
    if (upsertEstimateError?.message) {
      setNotification("Failed to save estimate", "error");
    }
  }, [setNotification, upsertEstimateError?.message]);

  if (estimateSaved) {
    return <Redirect to={`/estimates/${estimate.id}/edit`} />;
  }

  return (
    <Page>
      <PageContent>
        <SimpleHeader title="New Estimate" />
        <ConstrainedPageContent>
          <ContentContainer elevation={0}>
            <PageSection>
              <EstimateCreateForm estimate={estimate} isFetching={isSaving} onSaveRequested={onSaveRequested} />
            </PageSection>
          </ContentContainer>
        </ConstrainedPageContent>
      </PageContent>
    </Page>
  );
};

export default EstimateCreatePage;
