import * as React from "react";
import { TextField } from "@material-ui/core";
import { MarkdownEditor, TagsField, TagValue } from "../../misc";
import { ContentContainer, Form, FormSection, FormField, FormControl } from "../../layout";
import { EstimateTag } from "../../api/models";
import ClientSelector from "../../misc/ClientSelector";
import { EstimateClient } from "../../api/GraphQL/models";

interface InfoFormProps {
  name: string;
  description: string;
  client: EstimateClient | null;
  tags: EstimateTag[];
  availableTags: EstimateTag[];
  nameChanged: (name: string) => any;
  descriptionChanged: (description: string) => any;
  clientChanged: (client: EstimateClient | null) => any;
  tagsChanged: (tags: EstimateTag[]) => any;
}

interface InfoFormState {
  availableTags: EstimateTag[];
  mappedTags: TagValue[];
}

export class InfoForm extends React.Component<InfoFormProps, InfoFormState> {
  public state: InfoFormState = { availableTags: [], mappedTags: [] };

  public static getDerivedStateFromProps(nextProps: InfoFormProps, prevState: InfoFormState) {
    return nextProps.availableTags !== prevState.availableTags
      ? {
          ...prevState,
          availableTags: nextProps.availableTags,
          mappedTags: nextProps.availableTags
            ? nextProps.availableTags.map((tag) => {
                return { value: tag.id, label: tag.name };
              })
            : []
        }
      : prevState;
  }

  public render() {
    return (
      <ContentContainer elevation={0}>
        <Form fullWidth>
          <FormSection title="About" fullWidth>
            <FormField>
              <FormControl>
                <TextField label="Name" value={this.props.name} onChange={this.nameChanged} style={{ width: "300px" }} />
              </FormControl>
            </FormField>
            <FormField>
              <FormControl>
                <ClientSelector value={this.props.client} onChange={this.props.clientChanged} />
              </FormControl>
            </FormField>
            <FormField>
              <FormControl>
                <TagsField
                  floatingLabelText="Tags"
                  value={this.props.tags.map((t) => t.id)}
                  tags={this.state.mappedTags}
                  onValueChanged={this.onEstimateTagsChanged}
                />
              </FormControl>
            </FormField>
            <FormField>
              <MarkdownEditor labelText="Description" value={this.props.description} onChange={this.props.descriptionChanged} />
            </FormField>
          </FormSection>
        </Form>
      </ContentContainer>
    );
  }

  private nameChanged = (event: object) => {
    const name = (event as any).target.value;
    this.props.nameChanged(name);
  };

  private onEstimateTagsChanged = (selectedTags: string[]) => {
    const tags = this.props.availableTags.filter((t) => selectedTags.indexOf(t.id) >= 0);
    this.props.tagsChanged(tags);
  };
}
